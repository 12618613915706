import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2acbe8d0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _02219f6a = () => interopDefault(import('../pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _1a87f0ec = () => interopDefault(import('../pages/appCompany.vue' /* webpackChunkName: "pages/appCompany" */))
const _5054808c = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _ae9a7ce8 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _70af2b46 = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _039cde5e = () => interopDefault(import('../pages/development/index.vue' /* webpackChunkName: "pages/development/index" */))
const _7db9f632 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _27510986 = () => interopDefault(import('../pages/forget.vue' /* webpackChunkName: "pages/forget" */))
const _6478da72 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _0af5f83e = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _7cfab3f2 = () => interopDefault(import('../pages/lecturer/index.vue' /* webpackChunkName: "pages/lecturer/index" */))
const _47619949 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _339f421f = () => interopDefault(import('../pages/permission.vue' /* webpackChunkName: "pages/permission" */))
const _74fe0ef3 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _20125336 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _369961d0 = () => interopDefault(import('../pages/tpl.vue' /* webpackChunkName: "pages/tpl" */))
const _30ec12f2 = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _6abd30cf = () => interopDefault(import('../pages/development/estimate/index.vue' /* webpackChunkName: "pages/development/estimate/index" */))
const _9ad03184 = () => interopDefault(import('../pages/development/qualification/index.vue' /* webpackChunkName: "pages/development/qualification/index" */))
const _38000298 = () => interopDefault(import('../pages/development/study/index.vue' /* webpackChunkName: "pages/development/study/index" */))
const _6ca9a088 = () => interopDefault(import('../pages/home/rank.vue' /* webpackChunkName: "pages/home/rank" */))
const _2f9e4777 = () => interopDefault(import('../pages/integral/mall.vue' /* webpackChunkName: "pages/integral/mall" */))
const _6651c7b6 = () => interopDefault(import('../pages/investigate/view.vue' /* webpackChunkName: "pages/investigate/view" */))
const _038516d6 = () => interopDefault(import('../pages/watch/catePanel.vue' /* webpackChunkName: "pages/watch/catePanel" */))
const _57252dab = () => interopDefault(import('../pages/watch/course.vue' /* webpackChunkName: "pages/watch/course" */))
const _146000cb = () => interopDefault(import('../pages/watch/practice.vue' /* webpackChunkName: "pages/watch/practice" */))
const _55f97c15 = () => interopDefault(import('../pages/watch/task.vue' /* webpackChunkName: "pages/watch/task" */))
const _47f58782 = () => interopDefault(import('../pages/account/exam/detail.vue' /* webpackChunkName: "pages/account/exam/detail" */))
const _67fd2a93 = () => interopDefault(import('../pages/account/exam/examQuestion.vue' /* webpackChunkName: "pages/account/exam/examQuestion" */))
const _0a9ae366 = () => interopDefault(import('../pages/account/exam/examSuccess.vue' /* webpackChunkName: "pages/account/exam/examSuccess" */))
const _4597903f = () => interopDefault(import('../pages/account/exam/record.vue' /* webpackChunkName: "pages/account/exam/record" */))
const _29befef5 = () => interopDefault(import('../pages/account/plan/detail.vue' /* webpackChunkName: "pages/account/plan/detail" */))
const _567145d3 = () => interopDefault(import('../pages/development/estimate/batchEvaluation.vue' /* webpackChunkName: "pages/development/estimate/batchEvaluation" */))
const _6e76e48f = () => interopDefault(import('../pages/development/estimate/evaluation.vue' /* webpackChunkName: "pages/development/estimate/evaluation" */))
const _361f0c07 = () => interopDefault(import('../pages/development/estimate/report.vue' /* webpackChunkName: "pages/development/estimate/report" */))
const _4004e1bb = () => interopDefault(import('../pages/account/plan/task/add.vue' /* webpackChunkName: "pages/account/plan/task/add" */))
const _5a64d427 = () => interopDefault(import('../pages/account/plan/task/detail.vue' /* webpackChunkName: "pages/account/plan/task/detail" */))
const _51d0c5ea = () => interopDefault(import('../pages/knowledge/offcourse/components/AssessAttachment.vue' /* webpackChunkName: "pages/knowledge/offcourse/components/AssessAttachment" */))
const _283ef53c = () => interopDefault(import('../pages/account/exam/_id.vue' /* webpackChunkName: "pages/account/exam/_id" */))
const _3fbcaca2 = () => interopDefault(import('../pages/account/task/_id.vue' /* webpackChunkName: "pages/account/task/_id" */))
const _6eeee3ae = () => interopDefault(import('../pages/integral/goods/_id.vue' /* webpackChunkName: "pages/integral/goods/_id" */))
const _c00f51b2 = () => interopDefault(import('../pages/investigate/analyze/_id.vue' /* webpackChunkName: "pages/investigate/analyze/_id" */))
const _29780b72 = () => interopDefault(import('../pages/knowledge/course/_id.vue' /* webpackChunkName: "pages/knowledge/course/_id" */))
const _7a6f0304 = () => interopDefault(import('../pages/knowledge/offcourse/_id.vue' /* webpackChunkName: "pages/knowledge/offcourse/_id" */))
const _47e0c293 = () => interopDefault(import('../pages/knowledge/project/_id.vue' /* webpackChunkName: "pages/knowledge/project/_id" */))
const _4903b52b = () => interopDefault(import('../pages/lecturer/view/_id.vue' /* webpackChunkName: "pages/lecturer/view/_id" */))
const _f6e2b39a = () => interopDefault(import('../pages/live/watch/_watch.vue' /* webpackChunkName: "pages/live/watch/_watch" */))
const _39523461 = () => interopDefault(import('../pages/redirect/_path.vue' /* webpackChunkName: "pages/redirect/_path" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _2acbe8d0,
    name: "account___zh"
  }, {
    path: "/agreement",
    component: _02219f6a,
    name: "agreement___zh"
  }, {
    path: "/appCompany",
    component: _1a87f0ec,
    name: "appCompany___zh"
  }, {
    path: "/certificate",
    component: _5054808c,
    name: "certificate___zh"
  }, {
    path: "/community",
    component: _ae9a7ce8,
    name: "community___zh"
  }, {
    path: "/company",
    component: _70af2b46,
    name: "company___zh"
  }, {
    path: "/development",
    component: _039cde5e,
    name: "development___zh"
  }, {
    path: "/en",
    component: _7db9f632,
    name: "index___en"
  }, {
    path: "/forget",
    component: _27510986,
    name: "forget___zh"
  }, {
    path: "/home",
    component: _6478da72,
    name: "home___zh"
  }, {
    path: "/knowledge",
    component: _0af5f83e,
    name: "knowledge___zh"
  }, {
    path: "/lecturer",
    component: _7cfab3f2,
    name: "lecturer___zh"
  }, {
    path: "/login",
    component: _47619949,
    name: "login___zh"
  }, {
    path: "/permission",
    component: _339f421f,
    name: "permission___zh"
  }, {
    path: "/register",
    component: _74fe0ef3,
    children: [{
      path: "",
      component: _20125336,
      name: "register___zh"
    }]
  }, {
    path: "/tpl",
    component: _369961d0,
    name: "tpl___zh"
  }, {
    path: "/watch",
    component: _30ec12f2,
    name: "watch___zh"
  }, {
    path: "/development/estimate",
    component: _6abd30cf,
    name: "development-estimate___zh"
  }, {
    path: "/development/qualification",
    component: _9ad03184,
    name: "development-qualification___zh"
  }, {
    path: "/development/study",
    component: _38000298,
    name: "development-study___zh"
  }, {
    path: "/en/account",
    component: _2acbe8d0,
    name: "account___en"
  }, {
    path: "/en/agreement",
    component: _02219f6a,
    name: "agreement___en"
  }, {
    path: "/en/appCompany",
    component: _1a87f0ec,
    name: "appCompany___en"
  }, {
    path: "/en/certificate",
    component: _5054808c,
    name: "certificate___en"
  }, {
    path: "/en/community",
    component: _ae9a7ce8,
    name: "community___en"
  }, {
    path: "/en/company",
    component: _70af2b46,
    name: "company___en"
  }, {
    path: "/en/development",
    component: _039cde5e,
    name: "development___en"
  }, {
    path: "/en/forget",
    component: _27510986,
    name: "forget___en"
  }, {
    path: "/en/home",
    component: _6478da72,
    name: "home___en"
  }, {
    path: "/en/knowledge",
    component: _0af5f83e,
    name: "knowledge___en"
  }, {
    path: "/en/lecturer",
    component: _7cfab3f2,
    name: "lecturer___en"
  }, {
    path: "/en/login",
    component: _47619949,
    name: "login___en"
  }, {
    path: "/en/permission",
    component: _339f421f,
    name: "permission___en"
  }, {
    path: "/en/register",
    component: _74fe0ef3,
    children: [{
      path: "",
      component: _20125336,
      name: "register___en"
    }]
  }, {
    path: "/en/tpl",
    component: _369961d0,
    name: "tpl___en"
  }, {
    path: "/en/watch",
    component: _30ec12f2,
    name: "watch___en"
  }, {
    path: "/home/rank",
    component: _6ca9a088,
    name: "home-rank___zh"
  }, {
    path: "/integral/mall",
    component: _2f9e4777,
    name: "integral-mall___zh"
  }, {
    path: "/investigate/view",
    component: _6651c7b6,
    name: "investigate-view___zh"
  }, {
    path: "/watch/catePanel",
    component: _038516d6,
    name: "watch-catePanel___zh"
  }, {
    path: "/watch/course",
    component: _57252dab,
    name: "watch-course___zh"
  }, {
    path: "/watch/practice",
    component: _146000cb,
    name: "watch-practice___zh"
  }, {
    path: "/watch/task",
    component: _55f97c15,
    name: "watch-task___zh"
  }, {
    path: "/account/exam/detail",
    component: _47f58782,
    name: "account-exam-detail___zh"
  }, {
    path: "/account/exam/examQuestion",
    component: _67fd2a93,
    name: "account-exam-examQuestion___zh"
  }, {
    path: "/account/exam/examSuccess",
    component: _0a9ae366,
    name: "account-exam-examSuccess___zh"
  }, {
    path: "/account/exam/record",
    component: _4597903f,
    name: "account-exam-record___zh"
  }, {
    path: "/account/plan/detail",
    component: _29befef5,
    name: "account-plan-detail___zh"
  }, {
    path: "/development/estimate/batchEvaluation",
    component: _567145d3,
    name: "development-estimate-batchEvaluation___zh"
  }, {
    path: "/development/estimate/evaluation",
    component: _6e76e48f,
    name: "development-estimate-evaluation___zh"
  }, {
    path: "/development/estimate/report",
    component: _361f0c07,
    name: "development-estimate-report___zh"
  }, {
    path: "/en/development/estimate",
    component: _6abd30cf,
    name: "development-estimate___en"
  }, {
    path: "/en/development/qualification",
    component: _9ad03184,
    name: "development-qualification___en"
  }, {
    path: "/en/development/study",
    component: _38000298,
    name: "development-study___en"
  }, {
    path: "/en/home/rank",
    component: _6ca9a088,
    name: "home-rank___en"
  }, {
    path: "/en/integral/mall",
    component: _2f9e4777,
    name: "integral-mall___en"
  }, {
    path: "/en/investigate/view",
    component: _6651c7b6,
    name: "investigate-view___en"
  }, {
    path: "/en/watch/catePanel",
    component: _038516d6,
    name: "watch-catePanel___en"
  }, {
    path: "/en/watch/course",
    component: _57252dab,
    name: "watch-course___en"
  }, {
    path: "/en/watch/practice",
    component: _146000cb,
    name: "watch-practice___en"
  }, {
    path: "/en/watch/task",
    component: _55f97c15,
    name: "watch-task___en"
  }, {
    path: "/account/plan/task/add",
    component: _4004e1bb,
    name: "account-plan-task-add___zh"
  }, {
    path: "/account/plan/task/detail",
    component: _5a64d427,
    name: "account-plan-task-detail___zh"
  }, {
    path: "/en/account/exam/detail",
    component: _47f58782,
    name: "account-exam-detail___en"
  }, {
    path: "/en/account/exam/examQuestion",
    component: _67fd2a93,
    name: "account-exam-examQuestion___en"
  }, {
    path: "/en/account/exam/examSuccess",
    component: _0a9ae366,
    name: "account-exam-examSuccess___en"
  }, {
    path: "/en/account/exam/record",
    component: _4597903f,
    name: "account-exam-record___en"
  }, {
    path: "/en/account/plan/detail",
    component: _29befef5,
    name: "account-plan-detail___en"
  }, {
    path: "/en/development/estimate/batchEvaluation",
    component: _567145d3,
    name: "development-estimate-batchEvaluation___en"
  }, {
    path: "/en/development/estimate/evaluation",
    component: _6e76e48f,
    name: "development-estimate-evaluation___en"
  }, {
    path: "/en/development/estimate/report",
    component: _361f0c07,
    name: "development-estimate-report___en"
  }, {
    path: "/knowledge/offcourse/components/AssessAttachment",
    component: _51d0c5ea,
    name: "knowledge-offcourse-components-AssessAttachment___zh"
  }, {
    path: "/en/account/plan/task/add",
    component: _4004e1bb,
    name: "account-plan-task-add___en"
  }, {
    path: "/en/account/plan/task/detail",
    component: _5a64d427,
    name: "account-plan-task-detail___en"
  }, {
    path: "/en/knowledge/offcourse/components/AssessAttachment",
    component: _51d0c5ea,
    name: "knowledge-offcourse-components-AssessAttachment___en"
  }, {
    path: "/",
    component: _7db9f632,
    name: "index___zh"
  }, {
    path: "/en/account/exam/:id?",
    component: _283ef53c,
    name: "account-exam-id___en"
  }, {
    path: "/en/account/task/:id?",
    component: _3fbcaca2,
    name: "account-task-id___en"
  }, {
    path: "/en/integral/goods/:id?",
    component: _6eeee3ae,
    name: "integral-goods-id___en"
  }, {
    path: "/en/investigate/analyze/:id?",
    component: _c00f51b2,
    name: "investigate-analyze-id___en"
  }, {
    path: "/en/knowledge/course/:id?",
    component: _29780b72,
    name: "knowledge-course-id___en"
  }, {
    path: "/en/knowledge/offcourse/:id?",
    component: _7a6f0304,
    name: "knowledge-offcourse-id___en"
  }, {
    path: "/en/knowledge/project/:id?",
    component: _47e0c293,
    name: "knowledge-project-id___en"
  }, {
    path: "/en/lecturer/view/:id?",
    component: _4903b52b,
    name: "lecturer-view-id___en"
  }, {
    path: "/en/live/watch/:watch?",
    component: _f6e2b39a,
    name: "live-watch-watch___en"
  }, {
    path: "/account/exam/:id?",
    component: _283ef53c,
    name: "account-exam-id___zh"
  }, {
    path: "/account/task/:id?",
    component: _3fbcaca2,
    name: "account-task-id___zh"
  }, {
    path: "/en/redirect/:path?",
    component: _39523461,
    name: "redirect-path___en"
  }, {
    path: "/integral/goods/:id?",
    component: _6eeee3ae,
    name: "integral-goods-id___zh"
  }, {
    path: "/investigate/analyze/:id?",
    component: _c00f51b2,
    name: "investigate-analyze-id___zh"
  }, {
    path: "/knowledge/course/:id?",
    component: _29780b72,
    name: "knowledge-course-id___zh"
  }, {
    path: "/knowledge/offcourse/:id?",
    component: _7a6f0304,
    name: "knowledge-offcourse-id___zh"
  }, {
    path: "/knowledge/project/:id?",
    component: _47e0c293,
    name: "knowledge-project-id___zh"
  }, {
    path: "/lecturer/view/:id?",
    component: _4903b52b,
    name: "lecturer-view-id___zh"
  }, {
    path: "/live/watch/:watch?",
    component: _f6e2b39a,
    name: "live-watch-watch___zh"
  }, {
    path: "/redirect/:path?",
    component: _39523461,
    name: "redirect-path___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
